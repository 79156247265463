import { Box, Button, Card, Chip, Container, Divider, Grid, Modal, Typography } from "@mui/material";
import FeatureCard from './cards/FeatureCard';
import { useRef, useEffect, useState } from 'react';
import IconButton from '../img/IconButton.png';

const KeyFeatures = () => {

    const featuresStyles = { width: { xs: '100%', sm: '86%', md: '88%' }, height: { xs: '240px', md: '250px', lg: "200px" } };
    const advancedSolutionsStyles = { width: { xs: '100%', sm: '80%', md: '88%' }, height: { xs: '240px', sm: 'auto' } }


    const features = [
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-start' }, ...featuresStyles }, title: 'RFID & Mobile Keys', content: "Seamlessly integrating short and long-range access capabilities." },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-end' }, ...featuresStyles, marginLeft: { xs: '0', sm: '5px' } }, title: 'Elevators', content: "Our system ensured smooth interactions with the building's 16 TKE’s elevators. (TK Elevator© 2023)." },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-start' }, ...featuresStyles }, title: 'Gates Control', content: 'We managed access to four main Parking Gates and ten overhead doors exclusively designated for VIP parking.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-end' }, ...featuresStyles, marginLeft: { xs: '0', sm: '5px' } }, title: 'Door Access Control', content: 'Our system handled a staggering 705 doors, covering units, back-of-house areas, and amenities, all accessible remotely with status monitoring.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-start' }, ...featuresStyles }, title: 'Surveillance', content: 'We integrated 394 cameras in the unit lobbies (only controlled by the owner), enhancing security and facilitating a comprehensive package reception and delivery system.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-end' }, ...featuresStyles, marginLeft: { xs: '0', sm: '5px' } }, title: 'Valet Parking', content: 'Our Valet Parking System streamlined the parking experience for residents and guests.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-start' }, ...featuresStyles }, title: 'Amenities Scheduling', content: "Residents could conveniently schedule and control access to the building's amenities." },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-end' }, ...featuresStyles, marginLeft: { xs: '0', sm: '5px' } }, title: 'Employee Access', content: "Robust control mechanisms ensured secure management of employee access." },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-start' }, ...featuresStyles }, title: 'Guests & Visitors', content: "We provided a comprehensive solution for managing guest and visitor access." },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-end' }, ...featuresStyles, marginLeft: { xs: '0', sm: '5px' } }, title: 'Owner Control', content: "All of these features were accessible and controllable by the property owner through the user-friendly Gates Residences APP™." },

    ];

    const enhancedFeatures = [
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-start' }, ...featuresStyles }, title: 'CCTV Cameras', content: 'In addition to the unit lobbies, we deployed 191 CCTV cameras throughout general areas of the building to enhance overall security.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-end' }, ...featuresStyles, marginLeft: { xs: '0', sm: '5px' } }, title: 'Ambience Lighting', content: 'We installed 11,500 feet of strip LED lights with DMX protocol control over the network, allowing for dynamic and customizable lighting effects.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-start' }, ...featuresStyles }, title: 'Audio Experience', content: 'Common areas came alive with 330 speakers featuring Dante protocol over the network, ensuring a delightful auditory experience.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-end' }, ...featuresStyles, marginLeft: { xs: '0', sm: '5px' } }, title: 'WI-FI Coverage', content: 'We ensured seamless connectivity with over 1,000,000 square feet of WIFI coverage, guaranteeing residents and guests uninterrupted access to high-speed internet.' },
    ];

    const advancedSolutions = [
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-start' }, ...advancedSolutionsStyles }, title: 'Structured Cabling', content: 'Our expertise in structured cabling ensured a reliable and organized network infrastructure throughout the building, supporting seamless data and communication services.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'center', md: 'center' }, ...advancedSolutionsStyles, marginLeft: { xs: '0', sm: '5px' }, ...advancedSolutionsStyles }, title: 'Fiber Optics', content: 'We deployed cutting-edge fiber optic technology to enhance data transmission speed and reliability.' },
        { customStyles: { justifyContent: { xs: 'center', sm: 'flex-end', md: 'flex-end' }, ...advancedSolutionsStyles, marginLeft: { xs: '0', sm: '5px' }, ...advancedSolutionsStyles }, title: 'FTTH fiber Optic Network', content: "Our Fiber to the Home (FTTH) network brought lightning-fast internet speeds and high-definition multimedia directly to residents' doorsteps, revolutionizing their digital experience." },
    ]


    return (
        <Box backgroundColor='white' paddingX={{ xs: 5, sm: 8, lg: 20, xl: 30 }} paddingY={{ xs: 4, md: 8 }}>
            <Box display='flex' marginBottom={5}>
                <img src={IconButton} width={'45px'} height={'45px'} style={{ marginRight: 20 }} />
                <Typography fontSize={'26px'} letterSpacing={2} variant="h5" fontWeight={600} display={'flex'} alignItems={'center'}>KEY FEATURES</Typography>
            </Box>

            <Grid container alignItems="stretch">
                {
                    features.map((feature) => (
                        <Grid item xs={12} sm={6} marginBottom={3} display='flex' justifyContent={feature.customStyles?.justifyContent}>
                            <FeatureCard title={feature.title} content={feature.content} customStyles={feature.customStyles} />
                        </Grid>
                    ))
                }
            </Grid>

            <Box display='flex' marginTop={{ xs: 2, sm: 8 }} marginBottom={5}>
                <img src={IconButton} width={'45px'} height={'45px'} style={{ marginRight: 20 }} />
                <Typography fontSize={'26px'} letterSpacing={2} variant="h5" fontWeight={600} display={'flex'} alignItems={'center'}>ENHANCED BUILDING FEATURES</Typography>
            </Box>

            <Grid container alignItems="stretch">
                {
                    enhancedFeatures.map((feature) => (
                        <Grid item xs={12} sm={6} marginBottom={3} display='flex' justifyContent={feature.customStyles?.justifyContent}>
                            <FeatureCard title={feature.title} content={feature.content} customStyles={feature.customStyles} />
                        </Grid>
                    ))
                }
            </Grid>

            <Box display='flex' marginTop={{ xs: 2, sm: 8 }} marginBottom={5}>
                <img src={IconButton} width={'45px'} height={'45px'} style={{ marginRight: 20 }} />
                <Typography fontSize={'26px'} letterSpacing={2} variant="h5" fontWeight={600} display={'flex'} alignItems={'center'}>ADVANCED CONECTIVITY SOLUTIONS</Typography>
            </Box>

            <Grid container alignItems="stretch">
                {
                    advancedSolutions.map((feature) => (
                        <Grid item xs={12} sm={4} marginBottom={4} display='flex' justifyContent={feature.customStyles?.justifyContent}>
                            <FeatureCard title={feature.title} content={feature.content} customStyles={feature.customStyles} />
                        </Grid>
                    ))
                }
            </Grid>


        </Box>
    )
}

export default KeyFeatures;