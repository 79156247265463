import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material'

const FeatureCard = ({ title, content, customStyles }) => {

  return (
    <Box
      width={customStyles?.width ?? '80%'}
      height={customStyles?.height ?? '90%'}
      border={'solid black 2px'}
      borderRadius={'10px'}
      padding={'15px'}
      marginBottom={{ xs: 0, sm: 5 }}
      marginLeft={customStyles?.marginLeft ?? '0'}
      sx={{
        ':hover': {
          backgroundColor: '#222222',
          color: 'white',
        },
      }}
    >
      <Typography fontWeight={700} fontFamily={'DM Sans'} fontSize={{ xs: '20px', md: '22px' }} marginBottom={2}>{title}</Typography>
      <Typography fontFamily={'DM Sans'} fontSize={{ xs: '18px', md: '19px' }}>{content}</Typography>
    </Box>

  )
}

export default FeatureCard;