import { Grid, Typography, Button, Box, Divider } from "@mui/material";
import MultritronikTMBlack from '../img/MultitronikTMBlack.svg';
import { scrollToSection } from '../common/Helpers'

const Footer = () => {

    return (
        <Box
            backgroundColor="white"
            paddingX={{ xs: 5, sm: 8, lg: 20, xl: 30 }}
            paddingY={3}
        >
            <Grid container alignItems="center" spacing={2}>
                {/* Logo */}
                <Grid
                    item
                    xs={12}
                    md={3}
                    display="flex"
                    justifyContent={{ xs: "center", md: "flex-start" }}
                    alignItems="center"
                >
                    <Box>
                        <img src={MultritronikTMBlack}></img>
                    </Box>

                </Grid>

                {/* Divider below logo (only for xs) */}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: { xs: "block", md: "none" },
                        marginTop: "10px",
                    }}
                >
                    <Divider color="black" />
                </Grid>

                {/* Navigation Links */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    spacing={2}
                    order={{ xs: 2, md: 3 }}
                    display="flex"
                    justifyContent={{ xs: "center", md: "flex-end" }}
                    alignItems="center"
                >
                    <Box
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="center"
                        gap={2}
                        flexWrap="nowrap"
                        overflow="auto"
                    >
                        {["HOME", "ABOUT US", "PROJECTS", "CONTACT US"].map((section) => (
                            <Button
                                key={section}
                                onClick={() =>
                                    scrollToSection(section.toLowerCase().replace(" ", "-"))
                                }
                                sx={{
                                    textTransform: "none",
                                    whiteSpace: "nowrap",
                                    fontSize: { xs: "14px", md: "16px" },
                                    position: "relative",
                                    color: "black",
                                    fontWeight: "bold",
                                    "&::after": {
                                        content: '""',
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        width: "0%",
                                        height: "2px",
                                        backgroundColor: { md: "rgba(120, 43, 183, 1)" },
                                        transition: "width 0.3s ease",
                                    },
                                    "&:hover::after": {
                                        width: "100%",
                                    },
                                }}
                            >
                                {section}
                            </Button>
                        ))}
                    </Box>
                </Grid>

                {/* Divider below menu (only for xs) */}
                <Grid
                    item
                    xs={12}
                    order={{ xs: 2 }}
                    sx={{
                        display: { xs: "block", md: "none" },
                        marginTop: "10px",
                    }}
                >
                    <Divider color="black" />
                </Grid>

                {/* Copyright */}
                <Grid
                    item
                    xs={12}
                    md={3}
                    order={{ xs: 3, md: 2 }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography fontSize="12px" textAlign="center">
                        Copyright © 2025 <strong>Multitronik LLC.</strong>
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    );
};

export default Footer;
