import { useState, useEffect } from 'react';
import { Box, Grid, Typography, IconButton, Divider } from '@mui/material';
import ContactUsMap from '../img/ContactUsMap.svg';
import Location from '../img/pin_drop.svg';
import Mail from '../img/Mail.svg';
import Phone from '../img/Phone.svg';
import UpArrow from '../img/up_arrow.svg';
import UpArrowGreen from '../img/up_arrow_green.svg';

const ContactUs = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
        setHover(false)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Box paddingX={{ xs: 5, sm: 8, lg: 20, xl: 30 }} paddingY={{ xs: 4, md: 8 }} display={{ xs: 'flex' }} justifyContent={'center'} color='white'>
        <Box display="flex" width='100%' justifyContent="center">
          <Box width={{ xs: "90%", md: "80%" }} id='contact-us'>
            <Typography variant="h2" color="white" fontSize={{ xs: '27px', md: '32px' }} textAlign="center" marginBottom={3} fontWeight={600} fontFamily={'DM Sans'} letterSpacing={2.5}>
              CONTACT US
            </Typography>
            <Box display={'flex'} justifyContent={'center'} marginBottom={7}>
              <Divider color={'#73FF8D'} sx={{ width: { xs: '90%', sm: "60%", md: '40%', lg: '35%' } }} style={{ height: '3px' }} />
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography textAlign="center" fontSize="40px" fontFamily={"DMSansBold"} marginBottom={3} width={{ xs: '100%', md: "70%" }}>WE CAN ELEVATE YOUR PROJECT WITH OUR EXPERTISE </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography textAlign="center" fontSize={{ xs: '20px', lg: '26px' }}>At Multitronik LLC. we thrive on challenges and embrace opportunities to create intelligent, secure, and connected environments. </Typography>
            </Box>
            <Box>
              <Typography textAlign="center" fontSize={{ xs: '20px', lg: '26px' }}>The Aston Martin Residences project represents our dedication to delivering innovative solutions that redefine modern living. </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        paddingY={{ xs: 4, md: 8 }}
        paddingBottom={4}
        paddingX={{ xs: 5, sm: 8, lg: 20, xl: 30 }}
        backgroundColor='rgba(52, 51, 51, 1)'
        display={{ xs: 'inline-block', md: 'flex' }}
        color="white">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} display="flex" flexDirection="column" justifyContent="space-around">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginBottom={{ xs: 5, md: 0 }}>
              <Box marginBottom={{ xs: 1 }}>
                <img src={Mail} width={'30px'} style={{ objectFit: 'cover', height: '100%' }} />
              </Box>
              <Typography fontSize={{ xs: '14px', lg: '22px' }} letterSpacing={1.3}>info@multitronikllc.com</Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginBottom={{ xs: 5, md: 0 }}>
              <Box marginBottom={{ xs: 1 }}>
                <img src={Phone} width={'30px'} style={{ objectFit: 'cover', height: '100%' }} />
              </Box>
              <Typography fontSize={{ xs: '14px', lg: '22px' }} letterSpacing={1.3}>+1 305 731 2542</Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginBottom={{ xs: 5, md: 0 }}>
              <Box marginBottom={{ xs: 1 }}>
                <img src={Location} width={'30px'} style={{ objectFit: 'cover', height: '100%' }} />
              </Box>
              <Typography fontSize={{ xs: '14px', lg: '22px' }} textAlign="center" letterSpacing={1.3}>1200 Brickell Ave., Suite 1660</Typography>
              <Typography fontSize={{ xs: '14px', lg: '22px' }} letterSpacing={1.3}>Zip 33131, FL,USA</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box width={{ xs: '100%' }} display={'flex'} justifyContent={{ xs: 'center', lg: 'left' }} alignItems={'flex-start'}>
              <img src={ContactUsMap} style={{ objectFit: 'cover', width: '100%', borderRadius: '10px' }} />
            </Box>
          </Grid>
        </Grid>
      </Box >

      {/* Go to Top Button */}
      {
        showScrollButton && (
          <Box
            sx={{ display: { xs: 'none', lg: 'initial' } }}
            onClick={scrollToTop}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 1000,
              cursor: 'pointer',
            }}
          >
            <img
              src={hover ? UpArrowGreen : UpArrow}
              alt="Go to top"
              style={{ width: '50px', height: '50px', objectFit: 'contain' }}
            />
          </Box>
        )
      }
    </>
  );
};

export default ContactUs;
