import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rectangle from '../img/Rectangle.png';
import { Divider } from '@mui/material'

const LatestProject = () => {

  return (
    <Box paddingTop={6} paddingBottom={2} paddingX={{ xs: 5, sm: 8, lg: 20, xl: 30 }} paddingY={{ xs: 4, md: 8 }} backgroundColor='#F1F1F2' display={{ xs: 'inline-block', md: 'flex' }}>
      <Box width={'100%'} id="projects">
        <Typography variant="h2" color="#2A2A2B" fontSize={{ xs: '27px', md: '32px' }} textAlign="center" marginBottom={2} fontWeight={'bolder'} fontFamily={'DM Sans'} letterSpacing={3}>
          OUR LATEST PROJECT
        </Typography>

        <Box display={'flex'} justifyContent={'center'} marginBottom={{ xs: 3, sm: 7 }}>
          <Divider color={'#782BB7'} sx={{ width: { xs: '90%', sm: "60%", md: '40%', lg: '35%' } }} style={{ height: '3px' }} />
        </Box>

        <Box display={{ xs: 'inline-block', sm: 'flex' }} >
          <Box width={'100%'} display={'flex'} justifyContent={{ xs: 'center', lg: 'left' }} alignItems={'flex-start'} minHeight={{ xs: '450px', sm: '500px', md: 'inherit' }} marginBottom={2}>
            <Box width={{ xs: '100%' }} height={{ xs: '450px', sm: '500px', md: 'inherit' }}>
              <img src={Rectangle} width={'100%'} style={{ objectFit: 'cover', minWidth: '200px', height: '100%', borderRadius: '10px' }} />
            </Box>
          </Box>
          <Box width={'100%'} marginLeft={{ xs: 0, sm: 8 }}>
            <Typography letterSpacing={2} variant="h5" fontWeight={600} marginBottom={2} fontSize={{ xs: '20px', md: '30px' }}> ASTON MARTIN RESIDENCES </Typography>
            <Typography fontSize={{ xs: '16px', lg: '21px' }} fontFamily={'DM Sans'} lineHeight={1.5} marginBottom={1}>
              At <strong>Multitronik LLC. </strong>, we take immense pride in delivering state-of-the-art solutions that
              redefine security, convenience, and connectivity.
            </Typography>
            <Typography fontSize={{ xs: '16px', lg: '21px' }} fontFamily={'DM Sans'} lineHeight={1.5} marginBottom={1}>
              Our recent project at the prestigious
              <strong> Aston Martin Residences </strong> is a testament to our commitment to excellence.
            </Typography>
            <Typography fontSize={{ xs: '16px', lg: '21px' }} fontFamily={'DM Sans'} lineHeight={1.5} marginBottom={1}>
              Cutting-Edge <strong> Access Control System </strong> with Unmatched Versatility
            </Typography>
            <Typography fontSize={{ xs: '16px', lg: '21px' }} fontFamily={'DM Sans'} lineHeight={1.5}>
              Our access control scope at <strong>Aston Martin Residences </strong> encompassed a wide range of
              functionalities, offering unparalleled convenience and security to the residents.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default LatestProject;