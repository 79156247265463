import { Box, Typography, Button, Grid } from '@mui/material';
import LogoHero from '../img/LogoHero.png';
import HeroBack from '../img/HeroBack.png';
import MultitronikTM from '../img/MultitronikTM2.svg';
import { scrollToSection } from '../common/Helpers'

const Hero = () => {

  return (
    <Box
      id='home'
      paddingLeft={{ xs: 5, sm: 8, lg: 20, xl: 30 }}
      paddingRight={{ xs: 5, md: 0 }}
      paddingBottom={{ xs: 0, lg: 10 }}
      boxSizing='border-box'
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `
      linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 60%),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 60%),
      url(${HeroBack})
    `,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6} display={{ xs: 'flex', sm: 'inline-block' }} justifyContent={{ xs: 'flex-start', sm: 'inherit' }}>

          <Box marginTop={10} marginBottom={10} >
            <Typography
              variant="h2"
              color="white"
              sx={{
                fontSize: { xs: '32px', sm: '50px', lg: '70px' },
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
              }}
              fontFamily={'DMSansBold'}
              letterSpacing={2}
            >
              TECHNOLOGICAL <br /> SOLUTIONS <br /> IN MULTIPLE <br /> DIMENSIONS
            </Typography>
            <Box marginY={4}>
              <img src={MultitronikTM} width={'145px'} />
            </Box>

            <Button size={"large"} sx={{ backgroundColor: "rgba(120, 43, 183, 1)", color: "white" }} onClick={() => scrollToSection("contact-us")}>CONTACT US</Button>
          </Box>

        </Grid>
        <Grid item xs={0} md={6} display="flex">

          <Box
            display={{ xs: 'none', md: 'flex' }}
            alignItems="center"
            marginBottom={{ xs: 0, sm: '10px' }}
            width={{ xs: '60%', md: '100%' }}
            minWidth={'200px'}
          >
            <img src={LogoHero} style={{ objectFit: 'contain', width: '65%', marginTop: 100 }} />
          </Box>

        </Grid>
      </Grid>
    </Box>
  )
}

export default Hero;