import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import AboutUs from '../components/AboutUs';
import KeyFeatures from '../components/KeyFeatures'
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import LatestProject from '../components/LatestProject';

const MainPage = () => {

    return (
        <Box sx={{ backgroundColor: '#222222', width: '100%', display: 'inline-block', boxSizing: "border-box" }}>
            <AppBar position="static" style={{ backgroundColor: '#FFFFFF' }} paddingX={30} paddingY={10}>
                <NavBar />
            </AppBar>

            <Hero />
            <AboutUs />
            <LatestProject />
            <KeyFeatures />
            <ContactUs />
            <Footer />
        </Box>

    )
}

export default MainPage;