import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import "@fontsource/dm-sans/400-italic.css";
import Logo from '../img/navBarLogo2.svg';
import { scrollToSection } from '../common/Helpers'

const NavBar = () => {
  const pages = ['HOME', 'ABOUT US', 'PROJECTS', 'CONTACT US'];
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const handleToggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const onClickOnMenuItem = (page) => {
    scrollToSection(page.toLowerCase().replace(' ', '-'));
    setMobileMenuOpen(false);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'white',
        boxShadow: 'none',
        padding: { xs: '15px 15px', sm: '8px 35px 8px 45px' },
      }}
    >
      <Toolbar disableGutters>
        {/* Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img src={Logo} alt="Logo" width="155px" />
        </Box>

        {/* Mobile Menu Icon */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton onClick={handleToggleMobileMenu} color="inherit">
            <MenuIcon sx={{ color: 'black' }} />
          </IconButton>
        </Box>

        {/* Desktop Menu */}
        <Box
          sx={{
            display: { xs: 'none', md: 'flex', justifyContent: 'flex-end', flexGrow: 1 },
          }}
        >
          {pages.map((page) => (
            <Button
              key={page}
              onClick={() => onClickOnMenuItem(page)}
              sx={{
                mx: 1.5,
                color: 'black',
                fontWeight: 'bold',
                fontSize: '16px',
                whiteSpace: 'nowrap',
                position: 'relative',
                '&:hover': {
                  color: '#2A2A2B',
                },
                '&::after': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '0%',
                  height: '2px',
                  backgroundColor: 'rgba(120, 43, 183, 1)',
                  transition: 'width 0.3s',
                },
                '&:hover::after': {
                  width: '100%',
                },
              }}
            >
              {page}
            </Button>
          ))}
        </Box>
      </Toolbar>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <Box
          sx={{
            position: 'absolute',
            top: 59,
            left: 0,
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            padding: 2,
          }}
        >
          {pages.map((page) => (
            <Button
              key={page}
              onClick={() => onClickOnMenuItem(page)}
              sx={{
                my: 1,
                color: 'black',
                fontWeight: 'bold',
                fontSize: '16px',
                display: 'flex',
                textAlign: 'right',
                justifyContent: 'flex-end',
                width: '100%',
                '&:hover': {
                  color: '#2A2A2B',
                },
              }}
            >
              {page}
            </Button>
          ))}
        </Box>
      )}
    </AppBar>
  );
};

export default NavBar;
