import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontVariantLigatures: 'none',
        body1: {
            fontFamily: 'DMSans, Arial, sans-serif',
            lineHeight: 1.2,
            fontVariantLigatures: 'none'
        },
        h3: {
            fontFamily: 'DMSansBold, Arial, sans-serif',
        }
    },
});

export default theme;